import styled from "styled-components";


export const PageWrapper = styled.div`
  background-color: #e5e5e5;
  padding: 136px 0 310px;
  @media only screen and (max-width: 801px) {
    padding: 104px 0 123px;
  }
  @media only screen and (max-width: 480px) {
    padding: 52px 0 106px;
  }
  .container {
    background-image: url("../../../static/img/404.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    h1 {
      font-size: 144px;
      line-height: 174px;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #29323c;
      @media only screen and (max-width: 800px) {
        font-size: 56px;
        line-height: 68px;
      }
    }
    .h404 {
      color: #fc0045;
      position: relative;
      padding: 20px 0;
    }
    p {
      font-size: 24px;
      line-height: 29px;
      align-items: center;
      text-align: center;
      color: #29323c;
      padding-bottom: 48px;
      @media only screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 19px;
      }
    }
    a {
      padding: 24px 0;
      background: #69cf67;
      border-radius: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      max-width: 257px;
      .btnP{
        color: #ffffff;
        padding-bottom: 0;
      }
      &:hover {
        background: #59ba57;
      }
    }
  }
`;
